[v-cloak] {
  display: none;
}

[dir='rtl'] {
  .modal-header .btn-close {
    margin: 0px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

div#loader {
  z-index: 9999;
}

.loading-overlay-content {
  position: relative !important;
}

.loading-overlay {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  p {
    font-weight: 400;
    font-size: 25px;
    margin: 0px;
    color: #181818;
  }
}

//Offers Page
.home-offer-card {
  .home-offer-btns {
    .disabled {
      border-color: #bebebe;
      background-color: #bebebe;
    }
  }
}

//Testimonials
.home-effortless-slider {
  .effortless-thumnail-card {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 320%;

    iframe {
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: hidden;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
  }
}

//Offer single
.offer-info-bnr {
  .offer-info-bnr-cnt {
    .expired {
      color: var(--bs-danger);
    }
  }
}

//About us

.team-member-card {
}

.team-member-card .team-member-card-photo {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  height: 250px;
  width: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
}

@media screen and (max-width: 768px) {
  .team-member-card .team-member-card-photo {
    height: 112px;
  }
}

.team-member-card:hover .team-member-card-photo .team-member-card-hover {
  opacity: 1;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.team-member-card .team-member-card-photo .team-member-card-hover {
  cursor: pointer;
  top: 0;
  opacity: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.team-member-card .team-member-card-photo .team-member-card-hover p {
  font-size: 15px;
  line-height: 25px;
  color: #ffffff;
  font-weight: 300;
  margin-bottom: 0;
}

.team-modal {
  padding: 20px;
}

.team-modal .team-modal-content {
  flex: 1;
  margin: 0px 20px;
}

.team-modal .team-modal-photo {
  flex: 1;
}
.team-modal-photo img {
  width: 100%;
  max-width: 100%;
}

.team-modal-info h4 {
  font-size: 20px;
  line-height: 22px;
  color: #293228;
  font-weight: 400;
  margin-bottom: 0;
}
.team-modal-info span {
  color: #979797;
  font-size: 14px;
  line-height: normal;
  width: 100%;
  display: block;
}

.team-modal-bio {
  margin-top: 15px;
}

.team-modal-bio p {
  font-size: 15px;
  line-height: 25px;
  color: var(--color-tasheel-black);
  font-weight: 300;
  margin-bottom: 0;
  text-align: justify;
}

.raice-request-forms-tabs a {
  margin-top: 0px;
}

//Incident requests
.raice-request-forms-tabs nav a {
  background-color: #fff;
  border: 1px solid #bebebe !important;
  color: #181818;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  border-radius: 10px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  margin-left: 15px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}

.raice-request-forms-tabs nav a.active,
.raice-request-forms-tabs nav a:focus {
  background-color: #65bc47 !important;
  color: #fff !important;
  border: 1px solid #65bc47 !important;
}

.raice-request-forms-tabs nav a:hover {
  background-color: #fff !important;
  color: #808080 !important;
  border: 1px solid #bebebe !important;
}

.raice-request-forms-tabs,
.apply-form-main {
  .submit {
    text-align: center;
    align-items: center;
    border-radius: 10px !important;
    border: 0px !important;
    border-bottom: 0px !important;
  }
}

.board-report-card {
  .heading-type-2 {
    text-transform: capitalize;
  }
}

/**
*Credit cards
*/
@media screen and (max-width: 768px) {
  #fees-calculators .tab-pane.active.no-borders {
    border: 0px;
  }
}
#vue-calculator,
#vue-personal-calculator,
#vue-installment-calculator {
  .credit-cards-calculator-main {
    @media screen and (max-width: 768px) {
      padding: 0px 26px;
    }
  }

  .calculatorf-form-sec {
    @media screen and (max-width: 768px) {
      display: block;
      margin: 20px 0px;
    }

    &.show {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .calculator-results-sec {
    position: relative;

    .btn-close {
      display: none;
      --bs-btn-close-bg: url('data:image/svg+xml,<svg fill="%2365bc47" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-221.17 -221.17 903.12 903.12" xml:space="preserve" stroke="%2365bc47"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-221.17" y="-221.17" width="903.12" height="903.12" rx="451.56" fill="%23ffffff" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"></path></g></svg>');
      opacity: 1;
      top: 4px;
      right: 5px;
      position: absolute;
      width: 3rem;
      height: 3rem;
      background-size: 2.2rem;
    }

    @media screen and (max-width: 768px) {
      display: none;
      .btn-close {
        display: block;
      }
    }

    &.show {
      @media screen and (max-width: 768px) {
        margin-top: 0;
        display: block;
      }
    }
  }
}

.calculator-results-sec a {
  color: #65bc47;
}
.calculatorf-form-btns button {
  font-size: 15px;
  line-height: 16.5px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  border-radius: 10px;
  padding: 15px 39px;
  text-decoration: none;
  margin-right: 12px;
}

/**
* Find Us
*/
.find-us-map {
  width: 100%;
  height: 100vh;
  margin: 0;
  @media screen and (max-width: 768px) {
    height: 400px;
  }
}

.find-us-block-card {
  cursor: pointer;
}

.find-us-map img {
  max-width: inherit !important;
}

.find-us-btns a:first-child {
  display: block;
}

.working-hours-sec {
  width: 100%;
}

.service-branch-tabs {
  .filter-list a {
    text-decoration: none;
    cursor: pointer;
  }
}

/**
  *Calculator: Personal
  */
#vue-personal-calculator,
#vue-installment-calculator {
  .calculatorf-form-sec {
    max-width: 100%;
  }
  .calculator-results-sec {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .desktop-range-sliders .nav-link,
  .desktop-range-sliders .nav-link.active {
    background-color: transparent !important;
    border: none !important;
    padding: 0;
    margin-right: 15px;
  }

  .desktop-range-sliders .nav-link.active img {
    border: 1px solid #64bb47 !important;
    border-radius: 50%;
  }

  .desktop-range-sliders .nav-link.active {
    background-color: transparent !important;
    padding: 0;
    margin-right: 15px;
  }

  .desktop-range-sliders .nav-link img {
    width: 35px;
  }
}

/**
  *Offers
  */
.offer-info-bnr-btns .apply-now-btn {
  &.disabled {
    opacity: 0.5;
  }
}

/**
  *Partners
  */
#vue-partner-app {
}

/**
  *Pagination
  */

.pagination-main {
  .disabled {
    opacity: 0.5;
  }
}

[dir='rtl'] {
  .pagination-main img {
    transform: rotate(180deg);
  }
}
